import {reactive} from 'vue'
import {storeSession} from "@/stores/storeSession";
import axios from "axios";
import {$toast} from "@/config/toast";


export const storeReport = reactive({
    localStorageKey: 'reports',
    reports: [],
    header: {
        Authorization: 'Bearer ' + storeSession.token
    },
    setReports(reports) {
        this.reports = reports
        localStorage.setItem(this.localStorageKey, JSON.stringify(reports));
        // location.reload();
    },
    setSingleReport(newReports) {

        let isNew = true;
        for (let i in this.reports) {
            if (this.reports[i].id == newReports[0].id) {
                this.reports[i] = newReports[0];
                isNew = false;
                break;
            }
        }
        //if it's new I need to add it to the array
        if(isNew){
            this.reports.push(newReports[0]);
        }
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.reports));
    },
    clearReports() {
        this.reports = false
        localStorage.removeItem(this.localStorageKey)
    },
    getReportById(id) {
        const reports = this.getReports()
        return reports.find(x => x.id == id)
    },
    updateReport(reportId, section, prop, value) {
        let reports = this.getLocalReports()
        // console.log(reports[1]['consumptionSection']);
        for (let i in reports) {
            let report = reports[i]
            if (report.id == reportId) {
                // console.log(reportId, section, prop, value)
                // console.log(report[section][prop])

                if (report[section] && report[section][prop]) {
                    report[section][prop] = value
                }else if(report[section]){
                    report[section] = value
                }
            }
        }
        this.setReports(reports);
        // console.log(reports[1]['consumptionSection'])
    },
    updateReportSection(reportId, section, value) {
        let reports = this.getLocalReports()
        // console.log(section);
        for (let i in reports) {
            let report = reports[i]
            if (report.id === reportId) {
                // console.log(value, report.date)
                if(report[section]){
                    report[section] = value
                }
            }
        }
        this.setReports(reports)
    },
    updateSignatureSection(reportId, section, param, signatureData) {
        // console.log('########save signature', reportId, section,param, signatureData);
        let reports = this.getLocalReports()
        // console.log(reports);
        for (let i in reports) {
            let report = reports[i]
            if (report.id === reportId) {
                // console.log(value)
                // console.log(report[section])
                report.signature = signatureData
            }
        }
        this.setReports(reports)
    },
    getLocalReports() {
        if (localStorage.getItem(this.localStorageKey) !== null) {
            this.reports = JSON.parse(localStorage.getItem(this.localStorageKey))
            return this.reports
        } else {
            return this.getReports()
        }
    },
    async getReports( force = false) {
        if(!force) {
            if (localStorage.getItem(this.localStorageKey) !== null) {
                this.reports = JSON.parse(localStorage.getItem(this.localStorageKey))
                return this.reports
            }
        }
        await axios
            .get(process.env.VUE_APP_API_URL_REPORT, {
                headers: {
                    Authorization: 'Bearer ' + storeSession.token
                }
            })
            .then((response) => {
                this.setReports(response.data.reports);
                this.reorderReports();

                // if(reload) {
                //     location.reload();
                // }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    this.clearReports()
                    storeSession.clearToken()
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    this.clearReports()
                    storeSession.clearToken()
                } else {
                    // Something happened in setting up the request that triggered an Error
                    this.clearReports()
                    storeSession.clearToken()
                }
                console.log('### getReports', error);
            })

        return this.reports

    },
    async reorderReports(field = 'dateIso', direction = 'desc') {
        //verifico se esistono
        if (localStorage.getItem(this.localStorageKey) !== null) {
            this.reports = JSON.parse(localStorage.getItem(this.localStorageKey))
            console.log(field, direction);
            let reverse = false;
            if(direction === 'desc'){
                reverse = true;
            }

            //riordino numerico
            // this.reports.sort((a, b) => parseFloat(a.date) - parseFloat(b.date));

            //Riordino stringhe
            let reorderedReports = this.reports.sort(sort_by(field, reverse, (a) =>  a.toUpperCase()));
            //Assegno il nuovo array ordinato
            this.setReports(reorderedReports)

            return 1;
        }
    },
    async getSingleReport(reportId, reload = false, goHome = false) {
        await axios
            .get(process.env.VUE_APP_API_URL_SINGLE_REPORT+reportId.toString(), {
                headers: {
                    Authorization: 'Bearer ' + storeSession.token
                }
            })
            .then((response) => {
                let newReport = response.data.reports;
                this.setSingleReport(newReport);

                if(reload) {
                    location.reload();
                }
                if(goHome) {
                    location.href = '/';
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
                console.log('### getSingleReports', error);
            })

        return this.reports

    },
    async sendReportToServer(reportId = null, closeAfter = 1) {
        /**
         * funzione che prende i report locali, li invia al server remoto dal quale poi recupera nuovamente i report
         * e li inserisce nel localStorage
         * */
        let tmpReports = {};

        if (localStorage.getItem(this.localStorageKey) !== null) {
            tmpReports = JSON.parse(localStorage.getItem(this.localStorageKey))
        }

        //recupero il report che voglio caricare
        if(reportId){
            for(let i=0; i<tmpReports.length; i++){
                if(reportId == tmpReports[i].id){
                    let report = tmpReports[i];
                    tmpReports = [];
                    tmpReports.push(report);
                }
            }
        }

        if(tmpReports == {}){
            alert('report vuoti');
            return
        }
        console.log(closeAfter);
        await axios
          .post(
            process.env.VUE_APP_API_URL_REPORT_SYNC,
            {
                reports: tmpReports,
                closeAfter: closeAfter
            },
            {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer ' + storeSession.token
              },
              withCredentials: false
            }
          )
          .then((response) => {
              // console.log(response.data.result)
              if(response && response.data.result) {
                  $toast.open({
                      message: 'Rapportino caricato.',
                      position: 'top',
                      type: 'success'
                  });
              }else{
                  $toast.open({
                      message: 'Caricamento fallito.',
                      position: 'top',
                      type: 'error'
                  });
              }
              // this.$toast.success('Il rapportino è stato inviato.')
              // this.setReports(response.data.reports)
          })
          .catch((error) => {
              if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  this.clearReports()
                  storeSession.clearToken()
              } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  this.clearReports()
                  storeSession.clearToken()
              } else {
                  // Something happened in setting up the request that triggered an Error
                  this.clearReports()
                  storeSession.clearToken()
              }
              console.log('### storeReports', error);
          })

        return this.reports

    },
    async generatePdf(reportId) {
        // console.log(process.env.VUE_APP_API_URL_GENERATE_PDF, reportId);
        await axios
            .get(
                process.env.VUE_APP_API_URL_GENERATE_PDF+reportId,
                {
                headers: {
                    Authorization: 'Bearer ' + storeSession.token
                },
                withCredentials: false
            })
            .then((response) => {
                console.log(response.data);
                if(response && response.data.result) {
                    $toast.open({
                        message: 'Il file PDF è stato generato.',
                        position: 'top',
                        type: 'success'
                    });
                }else{
                    $toast.open({
                        message: 'Generazione PDF fallita.',
                        position: 'top',
                        type: 'error'
                    });
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
                // console.log('### storeProducts', error);
            })
    },
    async downloadPdf(reportId, reportCliente = 0){
        // console.log(process.env.VUE_APP_API_URL_DOWNLOAD_PDF, reportId);
        await axios
            .get(
                process.env.VUE_APP_API_URL_DOWNLOAD_PDF+reportId+"/"+reportCliente,
                {
                    headers: {
                        Authorization: 'Bearer ' + storeSession.token
                    },
                    responseType: 'blob',
                    withCredentials: false
                })
            .then((response) => {
                // console.log(response);

                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                if(reportCliente === 1){
                    link.setAttribute('download', 'rapportino-cliente-'+reportId+'.pdf'); //or any other extension
                }else{
                    link.setAttribute('download', 'rapportino-'+reportId+'.pdf'); //or any other extension
                }

                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);

                $toast.open({
                    message: 'Il pdf è stato scaricato con successo.',
                    position: 'top',
                    type: 'success'
                });
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response);
                    $toast.open({
                        message: 'Recupero PDF fallito.',
                        position: 'top',
                        type: 'error'
                    });
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
                // console.log('### storeProducts', error);
            })
    },
    async createReport(plantId) {
        // console.log(process.env.VUE_APP_API_URL_CREATE_REPORT, plantId);
        await axios
            .get(process.env.VUE_APP_API_URL_CREATE_REPORT+plantId, {
                headers: {
                    Authorization: 'Bearer ' + storeSession.token
                }
            })
            .then((response) => {
                if(response.data.result && response.data.createdId){
                    // console.log(response.data.createdId);
                    this.getSingleReport(response.data.createdId, false, true)
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            })
        return this.plants
    }
});

//Funzione ordinamento stringhe
const sort_by = (field, reverse, primer) => {

    const key = primer ?
        function(x) {
            return primer(x[field])
        } :
        function(x) {
            return x[field]
        };

    reverse = !reverse ? 1 : -1;

    return function(a, b) {
        return a = key(a), b = key(b), reverse * ((a > b) - (b > a));
    }
}
