<template>

    <div class="row mb-3">
        <div class="col-6">
            <!-- Split dropend button -->
            <div class="btn-group dropend">
<!--                <button type="button" class="btn btn-secondary">-->
<!--                    Split dropend-->
<!--                </button>-->

                <button v-if="isOnline" @click="syncFromServer" class="btn btn-primary">
                    <i class="fa fa-sync"></i> Scarica rapportini
                </button>
                <button v-else class="btn btn-danger disabled me-3">
                    <i class="fa fa-circle small"></i> nessuna connessione
                </button>


                <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="visually-hidden">Toggle Dropright</span>
                </button>
                <ul v-if="isOnline" class="dropdown-menu">
                    <!-- Dropdown menu links -->
                    <li><a class="dropdown-item" @click="forceGetCommonNotes" href="#">Scarica note</a></li>
                    <li><a class="dropdown-item"  @click="forceGetProducts" href="#">Scarica prodotti</a></li>
                    <li><a class="dropdown-item"  @click="forceGetPlants" href="#">Scarica impianti</a></li>
                </ul>
            </div>

        </div>
        <div class="col-6 text-end">
            <router-link
                :to="{ name: 'addReport', params: {} }"
                class="btn btn-success">
                <i class="fa fa-plus"></i> Crea rapportino
            </router-link>
        </div>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
          <th>ID</th>
          <th>
              Data
              <a href="#" @click="reorderReports('dateIso', 'desc')"><i class="fa fa-arrow-down"></i></a>&nbsp;
              <a href="#" @click="reorderReports('dateIso', 'asc')"><i class="fa fa-arrow-up"></i></a>
          </th>
          <th>
              Azienda
          </th>
          <th>
              Cliente
              <a href="#" @click="reorderReports('client', 'desc')"><i class="fa fa-arrow-down"></i></a>&nbsp;
              <a href="#" @click="reorderReports('client', 'asc')"><i class="fa fa-arrow-up"></i></a>
          </th>
          <th>Impianto</th>
          <th>Stato</th>
          <th>Azioni</th>
        </tr>
      </thead>
      <tbody>
        <tr class="" v-for="report in reports" :key="report.id">
          <td>{{ report.id }}</td>
          <td>{{ report.date }}</td>
          <td>{{ report.company }}</td>
          <td>{{ report.client }}</td>
          <td>{{ report.plant }}</td>
          <td>{{ report.status }}</td>
          <td class="">
            <router-link
                :to="{ name: 'reportForm', params: { 'id': report.id} }"
                class="btn btn-info">
              <i class="far fa-edit"></i> modifica
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>


</template>

<script>

import {storeReport} from "@/stores/storeReport";
import {storeProduct} from "@/stores/storeProduct";

export default {
name: 'AppReportList',
emits: ["callForceRerender"],
    data() {
        return {
            'reports': [],
            'products': [],
            'commonNotes': [],
            'isOnline': navigator.onLine
        }
    },
    methods: {
        async getReports() {
            this.reports = await storeReport.getLocalReports()
            // await storeReport.getSingleReport(39)
        },
        async getProducts() {
            this.products = await storeProduct.getLocalProducts()
        },
        async forceGetProducts() {
            this.products = await storeProduct.getLocalProducts(true)
        },
        async getCommonNotes() {
            this.commonNotes = await storeProduct.getLocalCommonNotes()
        },
        async forceGetCommonNotes() {
            this.commonNotes = await storeProduct.getLocalCommonNotes(true)
        },
        async getPlants() {
            this.plants = await storeProduct.getLocalPlants();
        },
        async forceGetPlants() {
            this.plants = await storeProduct.getLocalPlants(true);
        },
        async syncFromServer() {
            await storeReport.getReports(true);
            this.$emit('callForceRerender');
        },
        async reorderReports(field, direction) {
            await storeReport.reorderReports(field, direction);
            this.$emit('callForceRerender');
        }
    },
    mounted() {
        this.getReports();
        this.getProducts();
        this.getCommonNotes();
        this.getPlants();
        // console.log("### appReportList", this.reports)
        // console.log("### appProductList", this.products)
        // console.log("### appCommonNotes", this.commonNotes)
    }
}
</script>

<style scoped>

</style>
